<template>
  <div class="box">
    <div class="search">

<el-form label-width="120px" label-height="60px">
  <el-row>
  
    <el-col :span="8">
      <el-form-item label="商家名称：" prop="businessName">
        <el-input v-model="queryInfo.condition.businessName" placeholder="请输入" clearable></el-input>
      </el-form-item>
    </el-col>
    <el-col :span="8">
      
    </el-col>
    <el-col :span="8" style="display: flex; justify-content: right;">
      <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
      <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
    </el-col>
  </el-row>

</el-form>
</div>
    <div class="tab">
      <div class="add">
        <el-button type="primary" @click="added">新增分类</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%" height="calc(100vh - 380px)" border :header-cell-style="{
        'background-color': '#f2f2f2',
        'text-align': 'center',
        border: '1px solid #ddd',
      }">
        <el-table-column prop="businessName" width="200" show-overflow-tooltip label="商家名称" align="center">
        </el-table-column>
        <el-table-column prop="orderSq" label="分类序号" align="center">
        </el-table-column>
        <el-table-column prop="parentClassifyName" label="上级分类" show-overflow-tooltip align="center">
        </el-table-column>
        
        <el-table-column prop="classifyLevel" label="分类级别" align="center">
        </el-table-column>
        <el-table-column prop="classifyName" label="分类名称" show-overflow-tooltip align="center">
        </el-table-column>
        <el-table-column prop="commodityType" label="分类类型" align="center">
          <template slot-scope="scope">
            <el-button type="text" v-if='scope.row.commodityType == 1'>团购</el-button>
            <el-button type="text" v-if='scope.row.commodityType == 2'>外卖</el-button>
            <el-button type="text" v-if='scope.row.commodityType == 3'>积分商品</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="creatTime" label="创建时间" width="150" align="center">
        </el-table-column>
        <el-table-column prop=" " label="操作" width="250" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row.id)">编辑</el-button>
            <template>
              <el-popconfirm title="这是一段内容确定删除吗？" @onConfirm="handleDelte(scope.row.id)"
                @confirm="handleDelte(scope.row.id)">
                <el-button type="text" slot="reference">删除</el-button>
              </el-popconfirm>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="新增分类" :visible.sync="showDialog" width="600px" height="1000px">
      <div class="dia">
        <el-form label-width="100px" label-position="left">
          <el-row>
            <el-col span="16">
              <el-form-item label="选择商家">
                <el-input @focus="focus" v-model="from.businessName" type="text" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>

          </el-row>
          <el-row>
            <el-col span="16">
              <el-form-item label="分类序号">
                <el-input v-model="from.orderSq" type="text" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col span="16">
              <el-form-item label="上级分类">
                <el-select v-model="from.parentclassify" placeholder="请选择" style="width: 100%;"
                  @change="handleSelectChange">
                  <el-option v-for="item in taplist" :key="item.value" :label="item.classifyName"
                    :value="item.classify">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col span="16">
              <el-form-item label="分类名称">
                <el-input v-model="from.classifyName" type="text" placeholder="请输入" width="40%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col span="16">
              <el-form-item label="分类类型">
                <el-select v-model="from.commodityType" placeholder="请选择">
                  <el-option v-for="item in optionlx" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="but">
            <el-button size="medium" @click="showDialog = false">取消 </el-button>
            <el-button size="medium" type="primary" @click="bc()">保存 </el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog title="选择商家" :visible.sync="userDialog" width="500px" height="400px">
      <el-form label-width="80px" label-height="40px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商家名称：" prop="contactName">
              <el-input v-model="name" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display: flex; justify-content: right;">
           
            <el-button type="primary" @click="searchsj" icon="el-icon-search">搜索</el-button>
          </el-col>
        </el-row>

      </el-form>
      <div class="dia">
        <el-table :data="restaurants" style="width: 100%" height="calc(100vh - 480px)"
          @selection-change="handleSelectionChange" border :header-cell-style="{
            'background-color': '#f2f2f2',
            'text-align': 'center',
            border: '1px solid #ddd',
          }">
          <el-table-column type="selection" width="50"> </el-table-column>


          <el-table-column prop="businessName" label="商家名称" align="center">
          </el-table-column>
         
          <el-table-column prop="creatTime" label="创建时间" align="center">

          </el-table-column>


        </el-table>


        <div class="diafour" style="text-align: center;">
          <el-button type="danger" @click="userDialog = false">取消</el-button>
          <el-button type="primary" @click="userDialog = false">确定 </el-button>
        </div>
      </div>

    </el-dialog>
  </div>

</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      toplist: [{
        name: '全部',
        value: 33
      }, {
        name: '已签署',
        value: 33
      }, {
        name: '未签署',
        value: 33
      }],
      tableData: [{
        name: 'wdz'
      }],
      from: {
        classifyResource: '2'
      },
      showDialog: false,
      optionlx: [{
        value: '1',
        label: '团购'
      }, {
        value: '2',
        label: '外卖'
      }, {
        value: '3',
        label: '积分商品'
      }],
      taplist: [],
      value: '',
      value1: '',
      queryInfo: {
        condition: {

        },
        currPage: 1,
        pageCount: 1,
        pageSize: 10,
       
        
      },
      total: 0,
      userDialog:false,
      restaurants:[],
      business:'',
      name:''
    };
  },
  created(){
    

  },
  mounted() {
    this.getList()

  },
  methods: {
    handleSelectionChange(val){
      console.log(val);
      this.business = val[0]
      this.from.businessName = val[0].businessName
      var that =this
      that.$http.post("/commodityType/queryCommodityTypeList", {
        businessId: val[0].id,
        classifyLevel: 1
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.taplist = response.data.data
        }
      });

    },
    focus(){
      this.userDialog = true
      var that = this;
    that.$http.post('/business/queryBusinessList',{}).then(response => {
      console.log(response.data.data,'response.data.data');
      
        that.restaurants = response.data.data
        console.log(that.restaurants,'that.restaurants');
        
        
        
      })

    },
    added() {
      this.gettapList()
      this.showDialog = true
    },
    //获取数据
    getList() {
      var that = this;

      that.$http.post("/commodityType/list", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },
    search() {
      this.queryInfo.currPage = 1;
      // this.queryInfo.condition = this.from
      
      
      this.getList()
    },
    reset() {
      this.queryInfo.condition = {
        commodityName: '',

      }
      this.queryInfo.currPage = 1;
      this.getList()
    },
    bc() {

      var that = this;
      that.from.business = that.business
      that.from.classifyResource = 2
      that.$http.post("/commodityType/saveCommodityType", that.from).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.from = {}
          that.showDialog = false
          that.getList()
        }
      });

    },
    edit(id) {

      var that = this;

      that.$http.post("/commodityType/queryCommodityTypeDetail", {
        id: id
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.from = response.data.data;

        }
      });
      this.showDialog = true
    },
    handleDelte(id) {
      console.log('99');
      var that = this;
      this.$http.post("/commodityType/delete", {
        id: id
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.$message.success("删除成功");

          that.getList()
        }
      });
    },
    //获取上级分类集合
    gettapList() {
      var that = this;

      that.$http.post("/commodityType/queryCommodityTypeList", {
        classifyResource: 2,
        classifyLevel: 1
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.taplist = response.data.data
        }
      });
    },
    handleSelectChange(value) {
      // 根据选中的 value 查找对应的 item
      const selectedItem = this.taplist.find(item => item.classify === value);
      if (selectedItem) {
        const classify = selectedItem.classify;
        const classifyName = selectedItem.classifyName;
        // 然后可以在这里使用 classify 和 classifyName
        console.log('选中的 classify:', classify);
        console.log('选中的 classifyName:', classifyName);
        this.from.parentclassifyName = classifyName
        this.from.parentclassify = classify
      }
    },
          // 修改页数大小
          handleSizeChange (val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange (val) {
      this.queryInfo.currPage = val;
      this.getList()
    },
    searchsj(){
      var that = this;
      that.$http.post('/business/queryBusinessList',{
        businessName:that.name
      }).then(response => {
      console.log(response.data.data,'response.data.data');
      
        that.restaurants = response.data.data
        console.log(that.restaurants,'that.restaurants');
        
        
        
      })
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  .search {
    background-color: #fff;
    // height: 160px;
    box-sizing: border-box;
    padding: 10px;

  }

  .tab {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px
  }

  .dia {
    .but {
      display: flex;
      justify-content: space-between;
      padding: 30px 200px;
    }

    .diali {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .liname {
        width: 100px;
      }

      input {
        height: 30px;
      }
    }

  }

}
</style>